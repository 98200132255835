import axios from "axios";

const initialState = () => ({
  api: {
    get: {
      fetching: false,
      error: false,
      lang: "",
      values: {}
    },
    put: {},
    post: {},
    delete: {}
  }
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getTranslations(state) {
      return state.api.get.values
    }
  },
  mutations: {
    FETCHING(state, value) {
      state.api.get.fetching = !!value
    },

    FETCH_COMMIT(state, translations) {
      state.api.get.values = translations.values
    },

    FETCH_ERROR(state, value) {
      state.api.get.error = value
    }
  },
  actions: {
    fetchTranslations({ commit, state }) {
      commit("FETCHING", true)

      const stage = process.env.VUE_APP_STAGE === 'dev' ? 'content-dev' : 'content';
      const esTranslation = `https://static.educacionit.com/alumni/${stage}/es.json`;

      axios.get(esTranslation)
        .then(response => {
          state.api.get.values = response.data;
          commit("FETCHING", false)
        })
        .catch(error => {
          commit("FETCHING", false)
          commit("FETCH_ERROR", error)
          console.error("Error al cargar 'es' translation", error);
        });
    }
  }
}
