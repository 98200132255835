import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const enroll = args => {
  let params = new URLSearchParams();

  params.append('course_code', args.course_code);
  params.append('calendar_id', args.calendar.calendar_id);
  params.append('country_id', args.country_id);

  return Api.post(endpoints.get('enroll'), params,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

const redirect = args => {
  let params = new URLSearchParams();

  params.append('calendar_id', args.calendar_id);

  return Api.get(endpoints.get('redirect')+'?'+params.toString());
};

const activate = args => {
  let params = new URLSearchParams();

  params.append('course_code', args.course_code);
  params.append('calendar_id', args.calendar.calendar_id);

  return Api.post(endpoints.get('activate'), params,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export default {
  enroll,
  redirect,
  activate
};
