import axios from "axios";

const initialState = () => ({
  country: {
    "pais_id": "AR",
    "idioma_id": "es",
    "moneda_id": "ARS",
    "region_defecto_id": 3433955,
    "sucursal_defecto_id": 1,
    "nombre": "Argentina",
    "zona_horaria": "UTC-03:00",
    "multiplicador": 1,
    "descuento_global": 40,
    "default_timezone_id": "America/Argentina/Buenos_Aires",
    "moneda": {
      "moneda_id": "ARS",
      "nombre": "Peso Argentino"
    }
  },
  region: {
    "region_id": 3435907,
    "region_iso_id": "AR-B",
    "pais_id": "AR",
    "nombre": "Buenos Aires",
    "latitud": -36,
    "longitud": -60
  },
});

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async fetch({ state }) {
      try{
        const response = await axios.get(process.env.VUE_APP_EDUCACIONIT_API_SERVER + "init?include=pais,region", {});

        state.country = response.data.pais;
        state.region = response.data.region;
      } catch(e){
        console.error(e);
      }
    },
  },
  getters: {
    country: (state) => state.country,
    region: (state) => state.region,
  },
};
